<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">按日统计分析</h1>
    </div>
    <div slot="extra">
      <a-form layout="inline">
        <a-form-item label="日期">
          <a-date-picker :allowClear="false" format="YYYY-MM-DD" v-model="statisticsDate"
                         @change="statisticsDateChange" :disabledDate="disabledDate"></a-date-picker>
        </a-form-item>
        <a-form-item class="f-r m-r-none">
          <a-button-group class="m-r-12">
            <a-button @click="beforeDay">
              <a-icon type="left"/>
              前一天
            </a-button>
            <a-button @click="nextDay">后一天
              <a-icon type="right"/>
            </a-button>
          </a-button-group>
          <a-button type="primary" class="m-r-12" @click="searchByData('qt')">前天</a-button>
          <a-button type="primary" class="m-r-12" @click="searchByData('zt')">昨天</a-button>
          <a-button type="primary" @click="searchByData('jt')">今天</a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-row :gutter="24">
      <a-col :sm="24" :md="24" :lg="12" :xl="8" v-for="item in statisticsData" :key="item.driverId">
        <a-card class="project-list"
                style="margin-bottom: 24px;"
                :bordered="false"
                :body-style="{ padding: 0 }">

          <div slot="title">
            <a-icon type="user"/>
            &nbsp;<span style="font-weight: bold">{{ item.driverName }}</span>
          </div>
          <div slot="extra">
            <a-tooltip>
              <template slot="title">
                司机某一天的清运详细信息，拉运任务依次按照清运时间排列。
              </template>
              <a-icon type="exclamation-circle"/>
            </a-tooltip>
          </div>
          <a-card-grid style="width:25%;text-align:center;">
            <a-row>清运</a-row>
            <a-row><span style="font-size: larger;font-weight: bold;line-height: 30px;">{{ item.totalLoop }}</span>&nbsp;趟
            </a-row>
          </a-card-grid>
          <a-card-grid style="width:25%;text-align:center">
            <a-row>数量</a-row>
            <a-row><span style="font-size: larger;font-weight: bold;line-height: 30px;">{{ item.totalTon }}</span>&nbsp;吨
            </a-row>
          </a-card-grid>
          <a-card-grid style="width:25%;text-align:center">
            <a-row>任务</a-row>
            <a-row><span style="font-size: larger;font-weight: bold;line-height: 30px;">{{ item.totalTask }}</span>&nbsp;个
            </a-row>
          </a-card-grid>
          <a-card-grid style="width:25%;text-align:center">
            <a-row>行驶</a-row>
            <a-row><span style="font-size: larger;font-weight: bold;line-height: 30px;">{{ item.totalMileage }}</span>&nbsp;公里
            </a-row>
          </a-card-grid>
          <a-card-grid style="height: 400px;width:100%;overflow-y: auto;float: left;">
            <div style="width:100%;text-align:left;margin-bottom: 30px;" @click="clickItemLoopRecord(loopRecord)"
                 :key="index"
                 v-for="(loopRecord, index) in item.loopRecordList">
              <a-row style="margin-bottom: 6px">
                <a-col :span="24">
                  <a-avatar size="small" style="background-color: rgb(145,155,174);">{{ index + 1 }}</a-avatar>
                  &nbsp;&nbsp;&nbsp;在{{ loopRecord.startTime }}~{{ loopRecord.endTime }}（{{ loopRecord.duration }}），驾驶{{ loopRecord.carPlatNumber }}清运车，<span
                  v-if="loopRecord.status==='DOING'">清运执行中</span><span v-if="loopRecord.status==='COMPLETED'">行驶{{ loopRecord.mileage }}公里，已清运完成</span>。
                </a-col>
              </a-row>
              <a-divider dashed orientation="left">
              </a-divider>
              <a-row v-for="reportRecord in loopRecord.reportRecordList" :key="reportRecord.reportId"
                     style="text-align: left;padding: 10px 5px;">
                <a-icon style="color:#2ba245" type="wechat" v-if="reportRecord.collectType==='WECHAT'" theme="filled"/>
                <a-icon style="color:#2b97ff" type="phone" v-if="reportRecord.collectType==='REGISTER'" theme="filled"/>
                <a-icon style="color:#3686a2" type="import" v-if="reportRecord.collectType==='IMPORT'"/>
                <a-divider type="vertical"/>
                {{ reportRecord.userName }}
                <a-divider type="vertical"/>
                <a-tag v-if="reportRecord.type===spotTypeEnum.DANWENG.value" color="#00BFFF">
                  单翁
                </a-tag>
                <a-tag v-if="reportRecord.type===spotTypeEnum.JIWUCHI.value" color="#00CD66">
                  集污池
                </a-tag>
                <a-tag v-if="reportRecord.type===spotTypeEnum.SANGE.value" color="#00CED1">
                  三格化粪池
                </a-tag>
                <a-tag v-if="reportRecord.type===spotTypeEnum.LANI.value" color="#00CED1">
                  拉泥
                </a-tag>
                <a-tag v-if="reportRecord.type===spotTypeEnum.GONGCE.value" color="#00CED1">
                  公厕
                </a-tag>
                <a-tag v-if="reportRecord.type===spotTypeEnum.OTHER.value" color="#00CED1">
                  其他
                </a-tag>
                <a-divider type="vertical"/>
                {{ reportRecord.areaName }}
                <a-badge v-if="reportRecord.reportBindStatus==='COMPLETE'" status="success"/>
                <a-badge v-if="reportRecord.reportBindStatus==='DOING'" status="processing"/>
                <span style="float: right"><a-icon type="clock-circle"/>&nbsp;{{ reportRecord.time|formatDate }} </span>
              </a-row>
            </div>
          </a-card-grid>
        </a-card>
      </a-col>
    </a-row>
    <!--详情modal-->
    <a-modal :title="platNumber"
             :visible="detailDrawer"
             :width="1400"
             centered
             :footer="null"
             @cancel="detailClose">
      <LoopRecordDetail ref="viewRef"></LoopRecordDetail>
    </a-modal>
  </page-layout>
</template>

<script>
import PageLayout from '@/components/page/PageLayout'
import SERVICE_URLS from '@/api/service.url'
import moment from 'moment'
import LoopRecordDetail from '../../reportrecord/looprecord/Detail'
import { spotTypeEnum } from '@/constant/spot/spotType'

export default {
  name: 'LoopDay',
  components: { PageLayout, LoopRecordDetail },
  filters: {
    formatDate (date) {
      if (date) {
        return moment(date).format('MM-DD HH:mm')
      } else {
        return '~'
      }
    }
  },
  data () {
    return {
      platNumber: '',
      detailDrawer: false,
      cardLoading: false,
      statisticsDate: null,
      statisticsData: [],
      spotTypeEnum: spotTypeEnum
    }
  },
  mounted () {
    this.statisticsDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
    this.search()
  },
  methods: {
    beforeDay () {
      this.statisticsDate = moment(this.statisticsDate).subtract(1, 'days').format('YYYY-MM-DD')
      this.search()
    },
    nextDay () {
      if (moment(this.statisticsDate) > moment().subtract(1, 'days')) {
        this.$message.warning('不能选择后一天日期了')
        return false
      }
      this.statisticsDate = moment(this.statisticsDate).add(1, 'days').format('YYYY-MM-DD')
      this.search()
    },
    disabledDate (current) {
      if (!this.statisticsDate) return false
      return current > moment().add(0, 'd').endOf('day')
    },
    searchByData (type) {
      if (type === 'qt') {
        this.statisticsDate = moment().subtract(2, 'days').format('YYYY-MM-DD')
      } else if (type === 'zt') {
        this.statisticsDate = moment().subtract(1, 'days').format('YYYY-MM-DD')
      } else if (type === 'jt') {
        this.statisticsDate = moment().format('YYYY-MM-DD')
      }
      this.search()
    },
    statisticsDateChange (date, dateString) {
      this.statisticsDate = moment(dateString).format('YYYY-MM-DD')
      this.search()
    },
    clickItemLoopRecord (record) {
      console.info(record)
      this.detailDrawer = true
      this.platNumber = '车牌号：' + record.carPlatNumber
      this.$nextTick(() => {
        this.$refs['viewRef'].loadData(record.id)
      })
    },
    detailClose () {
      this.detailDrawer = false
    },
    search () {
      this.cardLoading = true
      this.$http(this, {
        url: SERVICE_URLS.csgz.dispatchAppApi.dispatchCurrentStatistics,
        data: {
          statisticsDate: this.statisticsDate
        },
        noTips: true,
        success: (data) => {
          this.cardLoading = false
          this.statisticsData = data.body
        }
      })
    }
  }
}
</script>